.body-style {
  /* background: #eee; */
  /* font-weight: lighter; */
  /* color: #444; */
}


/*the following are coming from jqueryevents*/
.border-dash {
  border: 1px dashed gray;
} 
      
[class*="--count"],
[class*="--which"] {
  font-size: 20pt;
}
      
/* *************************************************** */
.navbar-nav .nav-link:hover{
cursor: pointer;
background-color: rgb(189, 129, 129);
}

#search_field{
  display: block;
  margin: 0 auto;
  padding: 5px 10px;
  font-size: 28px; 
  width: 50%;
}



/* Map */
#map{
  width: 100%;
  height: 45vh;
  border: 1px solid #003;
}

#info {
  position: absolute;
  height: 100px;
  width: 100px;
  z-index: 9900; 
}





/* Avatar */

.user-img img {
  border-radius: 50%;
  width: 20%;
  background-color: #ccc;
}



/* VendingMachine Styles */

/* keep cells in single row for big screen */
@media (min-width: 768px) {
  .cell {
      min-height: 110px;
      max-height: 110px;
      float: left;
  }
}

.machine {
  margin: 30px 0 0 -30px;
}
.cell:hover {
  outline: 1px solid red;
  cursor: pointer;
}
.productInfo {
  padding-left: 20px;
}

.coin:hover{
  outline: 1px solid red;
  cursor: pointer;
  }
.payButton {
  width: 100px;
  height: 60px;
  margin-top: 20px;
  font-size: 2em;
  margin-left: 15px;
}

/* animation of products */
.selected .image {
  animation-name: rotate;
  animation-duration: 2s;
}

.sold .image {
  animation-name: drop;
  animation-duration: 2s;
}

@keyframes rotate {
  33% { transform: rotate(-15deg); }
  66% { transform: rotate(15deg); }
}

@keyframes drop {
  99% { transform: translateY(600px) rotate(120deg); }
}


/* End - VendingMachine Styles */










.footer-links a:hover {
  /* outline: 1px solid red; */
  cursor: pointer;
  background-color: rgb(100, 58, 58);
}

/* apply  */
footer{
  padding:45px 0 20px;
  line-height:24px;
  font-size:15px;
}

/* Footer on about page */
.site-footer
{
  background-color:#26272b; 
  font-size:15px;
  line-height:24px;
  color:#737373;
  padding:45px 0 20px;
  /* margin-top: 10px; */

}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}